<template>
  <div class="view-wrapper overflow-y-auto">
    <CalculatorComponent/>
  </div>
</template>

<script>
import CalculatorComponent from "./components/CalculatorComponent";

export default {
  name: "Calculator",
  components: {CalculatorComponent},
  data : () => ({

  })
}
</script>
